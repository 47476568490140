var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nextcloud-widget nextcloud-status-wrapper" },
    [
      _vm.notifications.length
        ? _c(
            "div",
            [
              _vm.canDeleteNotification("delete-all")
                ? _c("p", { staticClass: "group-action" }, [
                    _c(
                      "span",
                      {
                        staticClass: "action secondary",
                        on: { click: _vm.deleteNotifications },
                      },
                      [_vm._v(_vm._s(_vm.tt("delete-all")))]
                    ),
                  ])
                : _vm._e(),
              _c("hr"),
              _vm._l(_vm.notifications, function (notification, idx) {
                return _c("div", { key: idx, staticClass: "notification" }, [
                  _c("div", [
                    _c("img", {
                      attrs: { src: _vm.notificationIcon(notification.icon) },
                    }),
                  ]),
                  _c("div", [
                    _c("p", [
                      _c(
                        "small",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.dateTooltip(notification),
                              expression: "dateTooltip(notification)",
                            },
                          ],
                          staticClass: "date",
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getTimeAgo(
                                  Date.parse(notification.datetime)
                                )
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.subjectTooltip(notification),
                              expression: "subjectTooltip(notification)",
                            },
                          ],
                        },
                        [_vm._v(_vm._s(notification.subject) + " ")]
                      ),
                      notification.actions.length
                        ? _c(
                            "span",
                            _vm._l(
                              notification.actions,
                              function (action, idx) {
                                return _c("span", { key: idx }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "action",
                                      attrs: {
                                        href: action.link,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(action.label))]
                                  ),
                                ])
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                      _vm.canDeleteNotification("delete")
                        ? _c("span", [
                            _c(
                              "a",
                              {
                                staticClass: "action secondary",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteNotification(
                                      notification.notification_id
                                    )
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.tt("delete-notification")))]
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _c("hr"),
                ])
              }),
            ],
            2
          )
        : _c("div", { staticClass: "sep" }, [
            _c("p", [_vm._v(_vm._s(_vm.tt("no-notifications")))]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }